import { Entry } from '@lib/features/entries';
import { View } from '@lib/fetch/getViewByUri';
import { useView } from '@lib/store';
import Txt, { TxtProps } from '@primitive/Txt';
import { PageHeaderProps } from './PageHeader';

export type PageTitleProps = TxtProps<'h1'> & Partial<PageHeaderProps<View | Entry>>;

export const PageTitle = ({ view: maybeView, ...props }: PageTitleProps) => {
  const contextView = useView();
  const view = maybeView || contextView;

  if (!view) return null;

  const title = view.title;
  const pageTitle = 'pageTitle' in view && view.pageTitle;

  return (
    <Txt variant="h1" as="h1" {...props}>
      {pageTitle || title}
    </Txt>
  );
};
