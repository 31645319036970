import CardGenericWide from '@components/CardGeneric/CardGeneric.Wide';
import type { EntryCardProps } from '@components/EntryCard/EntryCard';
import { useEvent } from '@lib/hooks';

export const EntryCardWide = ({
  item,
  flipAlignment,
  ...props
}: EntryCardProps & { flipAlignment?: boolean }) => {
  const { eventDateFormatted } = useEvent(item);
  if (!item) return null;

  const { uri: href, title, entrySummary: description, entryImage: image } = item;

  return (
    <CardGenericWide
      {...props}
      flipAlignment={flipAlignment}
      padding="xl"
      item={{
        meta: eventDateFormatted ? [eventDateFormatted] : [],
        title,
        description,
        href,
        image,
      }}
    />
  );
};
