import Txt from '@componentPrimitives/Txt';
import { Entry } from '@lib/features/entries';
import { useView } from '@lib/store';
import { maybeGet } from '@liquorice/allsorts-craftcms-nextjs';
import { PageHeaderProps } from './PageHeader';

export const EntrySummary = ({ view: maybeView }: Partial<PageHeaderProps<Entry>>) => {
  const appView = useView();
  const view = maybeView ?? appView;
  const summary = maybeGet(view, 'entrySummary');

  if (!summary?.length) return null;

  return <Txt variant="large">{summary}</Txt>;
};
