import Container, { ContainerProps } from '@componentPrimitives/Container';
import { useDevMode } from '@components/_DevTestGrid';
import { useForwardedRef } from '@lib/utils/useForwardedRef';
import Box, { BoxProps } from '@primitive/Box';
import { Sprinkles } from '@theme/_sprinkles.css';
import classNames from 'classnames';
import { motion, MotionProps, useInView } from 'framer-motion';
import React from 'react';
import * as style from './BlockContainer.css';
import { useBlock, useBlocksContext } from './useBlock';

export type BlockContainerProps = BoxProps<
  typeof motion.section,
  style.BlockContainerVariants & {
    children?: React.ReactNode;
    disableContainer?: boolean;
    disableTransition?: boolean;
    ContainerProps?: ContainerProps;
    maxWidth?: ContainerProps['maxWidth'];
    onInView?: (inView: boolean) => void;
  } & Pick<Sprinkles, 'marginTop' | 'marginBottom'>
>;

export const BlockContainer = React.forwardRef(function BlockContainer(
  {
    children,
    className: classNameProvided,
    disableContainer,
    disableTransition,
    onInView,
    style: customStyleProp,
    disableMargin,
    marginTop,
    marginBottom,
    cx,
    maxWidth,
    ContainerProps,
    ...props
  }: BlockContainerProps,
  forwardedRef?: React.ForwardedRef<HTMLElement>
) {
  const blocksContext = useBlocksContext();
  const ref = useForwardedRef(forwardedRef);
  const isDev = useDevMode((v) => v.value);
  const { anchor, block } = useBlock();
  const isInView = useInView(ref, {
    once: true,
    margin: '0px 0px -100px 0px',
  });

  const isLast = !!block._blockMeta?.last;

  React.useEffect(() => {
    onInView && onInView(isInView);
  }, [isInView, onInView]);

  const styleProp: MotionProps['style'] = {
    ...(!disableTransition && {
      transition: 'opacity 0.4s ease-in',
      opacity: isInView ? 1 : 0,
    }),
    ...customStyleProp,
  };

  let inner = <>{children}</>;

  if (!disableContainer && !blocksContext.disableContainers)
    inner = (
      <Container
        {...{
          maxWidth,
          ...ContainerProps,
        }}>
        {children}
      </Container>
    );

  const className = classNames(
    classNameProvided,
    style.root({
      last: isLast,
    })
  );

  return (
    <>
      {isDev && <code>{block?.__typename.split('_')[1]}</code>}
      <Box
        as={motion.section}
        id={anchor}
        ref={ref}
        {...props}
        style={styleProp}
        cx={{
          marginTop: disableMargin || marginTop ? marginTop : '5xl',
          marginBottom: disableMargin || marginBottom ? marginBottom : '5xl',
          ...cx,
        }}
        className={className}>
        {inner}
      </Box>
    </>
  );
});
