import { createBlock } from '@lib/features/blocks/blocks';
import { Entry } from '@lib/features/entries';

/** Hook to apply card "overrides" and extra features */
const useEntryCardBlock = createBlock<'blocks_entryCard_BlockType', NoProps, Entry | null>(
  (props) => {
    const {
      entrySingle: item,
      heading: maybeHeading,
      content: maybeContent,
      image: maybeImage,
    } = props;

    // Bail early
    if (!item) return null;

    if (maybeHeading) item.title = maybeHeading;
    if (maybeContent) item.entrySummary = maybeContent;
    if (maybeImage) item.entryImage = maybeImage;

    return item as Entry;
  }
);

export default useEntryCardBlock;
