import Grid from '@componentPrimitives/Grid';
import Card, { CardProps } from '@components/Card';
import { CardBase } from '@components/Card/useCard';
import React from 'react';
import * as style from './CardGeneric.Wide.css';

export default function CardGenericWide<
  T extends CardBase = CardBase,
  D extends React.ElementType = 'article'
>({
  flipAlignment,
  ...props
}: CardProps<T, D> & {
  flipAlignment?: boolean;
}) {
  return (
    <Card {...(props as CardProps<T, D>)} padding="xl">
      <Grid
        noGutter
        cx={{ alignItems: 'center', flexDirection: flipAlignment ? 'row' : 'row-reverse' }}>
        <Grid.Col lg={7} className={style.imageCol}>
          <Card.Image priority ratio="landscapeWide" ShimProps={{ className: style.imageWrap }} />
        </Grid.Col>
        <Grid.Col lg={5}>
          <Card.Body cx={{ padding: '2xl', spacing: 'md' }}>
            <Card.Meta />
            <Card.Title />
            <Card.Description />
            <Card.Cta />
          </Card.Body>
        </Grid.Col>
      </Grid>
    </Card>
  );
}
