import { BlockContainer } from '@components/Blocks';
import EntryCard from '@components/EntryCard/EntryCard';
import { EntryCardWide } from '@components/EntryCard/EntryCard.Wide';
import useEntryCardBlock from '@components/EntryCard/useEntryCardBlock';
import { createBlock } from '@lib/features/blocks/blocks';

export type EntryCardBlockProps = {
  variant?: 'default' | 'feature';
};

const EntryCardBlock = createBlock<'blocks_entryCard_BlockType', EntryCardBlockProps>(
  ({ variant = 'default', flipAlignment, ...props }) => {
    const item = useEntryCardBlock(props);

    if (!item) return null;

    switch (variant) {
      case 'feature':
        return (
          <BlockContainer>
            <EntryCardWide /* {...props} */ flipAlignment={!!flipAlignment} item={item} />
          </BlockContainer>
        );
      default:
        return <EntryCard fullHeight item={item} />;
    }
  }
);

export default EntryCardBlock;
