import Box, { BoxProps } from '@componentPrimitives/Box';
import Container, { ContainerProps } from '@componentPrimitives/Container';
import Grid, { GridColProps } from '@componentPrimitives/Grid';
import Breadcrumbs from '@components/Breadcrumbs';
import { Sprinkles } from '@theme/_sprinkles.css';
import React from 'react';
import { PageTitle, PageTitleProps } from './PageTitle';

export type PageHeaderRootProps = BoxProps<
  'header',
  {
    PageTitleProps?: PageTitleProps;
    variant?: 'standard' | 'overview';
    maxWidth?: ContainerProps['maxWidth'];
    show?: {
      date?: boolean;
      author?: boolean;
    };
    beforeTitle?: React.ReactNode;
    afterGrid?: React.ReactNode;
    afterContainer?: React.ReactNode;
  }
>;

export const PageHeaderRoot = ({
  PageTitleProps,
  children,
  variant = 'standard',
  maxWidth,
  beforeTitle,
  afterGrid,
  afterContainer,
  cx: customCx,
  ...props
}: PageHeaderRootProps) => {
  const cx: Sprinkles = { pY: '2xl', ...customCx };
  const gridColProps: GridColProps = {
    cx: {
      display: 'flex',
      spacing: 'md',
      flexDirection: 'column',
    },
    ...(variant === 'overview' && {
      lg: 7,
    }),
  };

  return (
    <Box as="header" {...props} cx={cx}>
      <Container maxWidth={maxWidth}>
        <Grid>
          <Grid.Col {...gridColProps}>
            <Breadcrumbs />
            {beforeTitle}
            <PageTitle {...PageTitleProps} />
            {children}
          </Grid.Col>
        </Grid>
        {afterGrid}
      </Container>
      {afterContainer}
    </Box>
  );
};
