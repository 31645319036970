import Icon from '@component/Icon';
import Link, { LinkProps } from '@component/Link';
import Box, { BoxProps } from '@componentPrimitives/Box';
import { parseCommonLink } from '@lib/features/commonLinks';
import { isEntry } from '@lib/features/entries';

import { useAppLink, useView } from '@lib/store';
import { maybeGet, mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import React from 'react';
import * as styles from './Breadcrumbs.css';

export type BreadcrumbsProps = BoxProps & {
  // includeCurrent?: boolean;
};

const Breadcrumbs = (props: BreadcrumbsProps) => {
  // const t = useTranslations('common');
  const view = useView();

  const home = useAppLink('home');
  const articleIndex = useAppLink('articleIndex');
  const eventIndex = useAppLink('eventIndex');
  const profileIndex = useAppLink('profileIndex');
  const learningIndex = useAppLink('learningIndex');
  // const caseStudyIndex = useAppLink('caseStudyIndex');
  // const insightIndex = useAppLink('insightIndex');
  const maybeParent = maybeGet(view, 'parent');
  const parent = maybeParent ? parseCommonLink(maybeParent) : home;
  let ancestors = parent ? [parent] : [];

  switch (true) {
    case isEntry(view, 'article'):
      if (articleIndex) ancestors = [articleIndex];
      break;
    case isEntry(view, 'event'):
      if (eventIndex) ancestors = [eventIndex];
      break;
    case isEntry(view, 'profile'):
      if (profileIndex) ancestors = [profileIndex];
      break;
    case isEntry(view, 'learning'):
      if (learningIndex) ancestors = [learningIndex];
      break;
  }

  const sep = <Icon name={'arrowLeft'} />;

  const linkProps: Partial<LinkProps> = {
    underline: 'hover',
    variant: 'bodyAlt',
  };

  return (
    <Box {...mergePropsClassName(props, styles.root)}>
      {ancestors?.map((v, i) => (
        <React.Fragment key={i}>
          {sep}
          <Link href={v.uri ?? ''} {...linkProps}>
            {v.title}
          </Link>
        </React.Fragment>
      ))}
    </Box>
  );
};

export default Breadcrumbs;
