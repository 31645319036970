import Btn from '@componentPrimitives/Btn';
import Box, { BoxProps } from '@primitive/Box';
import Txt from '@primitive/Txt';

import { useViewBlocks } from '@lib/store';
import { useScrollToElement } from '@lib/utils/useScrollToElement';

import { useTranslations } from 'next-intl';

export const BlocksAnchorMenu = (props: BoxProps<'nav'>) => {
  const t = useTranslations('common');
  const scrollToElement = useScrollToElement();

  const { anchors } = useViewBlocks();

  const handleClick = (id: string) => (ev: React.MouseEvent<HTMLAnchorElement>) => {
    ev.preventDefault();
    scrollToElement(`#${id}`);
  };

  return anchors.length ? (
    <Box as="nav" {...props}>
      <Txt variant="h6" as="h2" cx={{ mB: 'md' }}>
        {`${t(`onThisPage`)}:`}
      </Txt>
      <Box
        cx={{
          display: 'flex',
          flexDirection: 'row',
          spacing: '2xs',
          flexWrap: 'wrap',
        }}>
        {anchors.map(([id, label]) => (
          <Btn key={id} onClick={handleClick(id)} variant="outlined" href={`#${id}`}>
            {label}
          </Btn>
        ))}
      </Box>
    </Box>
  ) : (
    <></>
  );
};
